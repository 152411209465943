/* eslint-disable max-lines-per-function, array-callback-return, camelcase, new-cap */
import $ from 'jquery';
import Tooltip from 'tooltip.js';

import { getDomElement, getDomElements, makeHtml } from '../utils/dom';

const chatSVG = (className) => `
  <svg class="tiger-popper-chat-icon  ${
    className || ``
  }" xmlns="http://www.w3.org/2000/svg"  width="27"  height="27"  viewBox="0 0 24 24">
<path fill-rule="evenodd" clip-rule="evenodd" class="intercom-icon" d="M13.6288 20.4718L13.0867 21.3877C12.6035 22.204 11.3965 22.204 10.9133 21.3877L10.3712 20.4718C9.95073 19.7614 9.74049 19.4063 9.40279 19.2098C9.06509 19.0134 8.63992 19.0061 7.78958 18.9915C6.53422 18.9698 5.74689 18.8929 5.08658 18.6194C3.86144 18.1119 2.88807 17.1386 2.3806 15.9134C2 14.9946 2 13.8297 2 11.5V10.5C2 7.22657 2 5.58985 2.7368 4.38751C3.14908 3.71473 3.71473 3.14908 4.38751 2.7368C5.58985 2 7.22657 2 10.5 2H13.5C16.7734 2 18.4101 2 19.6125 2.7368C20.2853 3.14908 20.8509 3.71473 21.2632 4.38751C22 5.58985 22 7.22657 22 10.5V11.5C22 13.8297 22 14.9946 21.6194 15.9134C21.1119 17.1386 20.1386 18.1119 18.9134 18.6194C18.2531 18.8929 17.4658 18.9698 16.2104 18.9915C15.36 19.0061 14.9349 19.0134 14.5972 19.2098C14.2595 19.4062 14.0492 19.7614 13.6288 20.4718ZM8 11.75C7.58579 11.75 7.25 12.0858 7.25 12.5C7.25 12.9142 7.58579 13.25 8 13.25H13.5C13.9142 13.25 14.25 12.9142 14.25 12.5C14.25 12.0858 13.9142 11.75 13.5 11.75H8ZM7.25 9C7.25 8.58579 7.58579 8.25 8 8.25H16C16.4142 8.25 16.75 8.58579 16.75 9C16.75 9.41421 16.4142 9.75 16 9.75H8C7.58579 9.75 7.25 9.41421 7.25 9Z"/>
</svg>`;


const helpLinks = `
    <div class="modal-links-container" >
      <a class="modal-links modal-link-one" data-t="help-modal-user-guide" target="_blank" rel="noopener" href="https://help.pagetiger.com/">
        <svg class="modal-links-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47 60">
          <path d="M38.91,0H6.5V60h47V0ZM17.5,14h10a1,1,0,0,1,0,2h-10a1,1,0,0,1,0-2Zm25,34h-25a1,1,0,0,1,0-2h25a1,1,0,0,1,0,2Zm0-8h-25a1,1,0,0,1,0-2h25a1,1,0,0,1,0,2Zm0-8h-25a1,1,0,0,1,0-2h25a1,1,0,0,1,0,2Zm0-8h-25a1,1,0,0,1,0-2h25a1,1,0,0,1,0,2Z" transform="translate(-6.5)"></path>
        </svg>

        <p class="modal-links-heading">User Guide</p>
      </a>

      <a id="help-popup" class="modal-links modal-link-one js-activate-chat" data-micromodal-close data-t="help-modal-contact-us">
        ${chatSVG(`modal-links-svg`)}
        <p class="modal-links-heading">Contact Us</p>
      </a>

      <a class="modal-links modal-link-one"
        href="${window.tigerlabsUrl}"
        rel="noopener"
        target="_blank"
        data-t="help-modal-tiger-labs">
        <svg
          class="modal-links-svg"
          viewBox="0 0 415.02 512"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M454.42,407.68,326,179.38V58.29A30,30,0,0,0,316,0H196a30,30,0,0,0-10,58.29V179.38L57.58,407.68a70,70,0,0,0,61,104.32H393.41a70,70,0,0,0,61-104.32ZM216,292a30,30,0,1,1,30-30A30,30,0,0,1,216,292Zm40-100a10,10,0,1,1,10-10A10,10,0,0,1,256,192Zm40,60a10,10,0,1,1,10-10A10,10,0,0,1,296,252Z"
            transform="translate(-48.49)"
          />
        </svg>
        <p class="modal-links-heading">TigerLabs</p>
      </a>

      <a class="modal-links modal-link-one" data-t="help-modal-email-support" href="mailto:support@pagetiger.com">
        <svg class="modal-links-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 10">
          <path d="M7,9,5.27,7.48.32,11.73A1,1,0,0,0,1,12H13a1,1,0,0,0,.69-.27L8.73,7.48Z" transform="translate(0 -2)"></path>
          <path d="M13.68,2.27A1,1,0,0,0,13,2H1a1,1,0,0,0-.69.27L7,8Z" transform="translate(0 -2)"></path>
          <polygon points="0 0.88 0 9.19 4.83 5.08 0 0.88" />
          <polygon points="9.17 5.08 14 9.19 14 0.88 9.17 5.08" />
        </svg>
        <p class="modal-links-heading">Email Support</p>
      </a>
    </div>`;

const registerToolTips = () => {
  // eslint-disable-next-line array-callback-return
  getDomElements('[data-toggle="tooltip"]').map(el => {
    // eslint-disable-next-line no-new
    new Tooltip(el, {
      arrowSelector: 'tooltip-arrow',
      container: 'body',
      placement: 'right',
      title: el.getAttribute('data-title') ?? ''
    });
  });
};

const getIntercomUnreadMessages = () => {
  if (window.Intercom) {
    const originalPageTitle = document.title;

    // As soon as the window is active the unread count sets itself to zero
    window.Intercom('onUnreadCountChange', unreadCount => {
      getDomElements('.js-set-chat-notification').map(el => {
        if (unreadCount >= 1) {
          // Cannot use the `classList` api as IE11 doesn't support it on SVG elements 🤦‍♂️
          el.setAttribute('class', `${el.getAttribute('class')} mod-show`);
          document.title = `(${unreadCount}) ${originalPageTitle}`;
        } else {
          const updatedClassName = el.getAttribute('class').replace(/mod-show/u, '');

          el.setAttribute('class', updatedClassName);
          document.title = originalPageTitle;
        }
      });
    });
  }
};

// Force removal of the video from the DOM (this stops it playing)  when the modal is closed.
const removeVideoContainer = () => {
  getDomElement('.js-remove-on-modal-close').map(el => {
    // eslint-disable-next-line no-param-reassign
    el.parentNode.innerHTML = '';
  });
};

$(document).on('click', '.modal-close-button', removeVideoContainer);

$(document).on('keyup', e => {
  if (e.originalEvent.keyCode === 27) {
    removeVideoContainer();
  }
});

function toggleChat() {
  var widget = document.querySelector('#support-chat-container');
  widget.classList.toggle('hide');
}


const setupHelp = () => {
  const $$ = document.querySelector.bind(document);
  const hideHelp = $$('#hdnHideHelp');
  
  if (hideHelp && hideHelp.value === 'true') {
    // Help is hidden - Don't do setup
    return;
  }


  if (window.Intercom) {
    renderTigerPopper();
  } else {
    window.hsConversationsOnReady = [
      () => {
        renderTigerPopper();


        body.addEventListener('click', ({ target }) => {
          if(target.classList.contains('js-activate-chat')) {
            toggleChat();
          }
        });

        document.getElementById('close-widget').addEventListener('click', function() {
          window.HubSpotConversations.widget.close();
          toggleChat();

        });

     

        document.querySelector('.js-activate-help-chat').addEventListener('click', function() {
          toggleChat();
        });
      },
    ];
  }


  registerToolTips();

  const modal = Object.create(window.Modal);

  getDomElement('body').map(body => {
    body.addEventListener('click', ({ target }) => {
      if (target.classList.contains('js-activate-help')) {
        // eslint-disable-next-line no-unused-expressions
        window.Intercom && window.Intercom('hide');
        const isPlayVideoButton = target.classList.contains('mod-button-icon');
        const shouldAutoPlay = isPlayVideoButton || window.autoPlayVideo;

        modal.init({
          body: makeHtml(`
            <div class="modal-help">
            ${
              window.videoUrl
                ? `<div class="modal-video-container">
                <video class="modal-video-element js-remove-on-modal-close" controls controlslist="nodownload" ${
                  shouldAutoPlay && `autoplay`
                }>
                  <source src="${window.videoUrl}" type="video/mp4">
                </video>
              </div>`
                : ``
            }
              ${isPlayVideoButton ? '' : helpLinks}
            </div>`),
          footer: makeHtml(
            '<button class="tertiary-link-style-button" data-t="help-modal-footer-close" data-micromodal-close type="button">Close</button>'
          ),
          isLarge: true,
          modalType: 'help',
          title: 'Page Tiger Help'
        });

        document.querySelector("#help-popup").addEventListener('click', function() {
          toggleChat();
        });
        
      }
    });
  });

  if (window.autoPlayVideo) {
    getDomElement('.js-activate-help').map(el => el.click());
  }

  getIntercomUnreadMessages();
};

const renderTigerPopper = () => {
  document.body.appendChild(
    makeHtml(`
    <div class="tiger-popper">
      ${
        window.videoUrl
          ? `<button class="tiger-popper-button mod-button-icon js-activate-help" data-t="video-help-popper-button" type="button" data-toggle="tooltip" data-placement="right" data-title="Video Help">
              <span class="tiger-popper-hide">Contact Us</span>
                <svg class="tiger-popper-play-icon mod-rotate" xmlns="http://www.w3.org/2000/svg" x="0" y="0" width="27" height="24" viewBox="0 0 373.008 373.008">
                  <path d="M61.792,2.588C64.771,0.864,68.105,0,71.444,0c3.33,0,6.663,0.864,9.655,2.588l230.116,167.2
                  c5.963,3.445,9.656,9.823,9.656,16.719c0,6.895-3.683,13.272-9.656,16.713L81.099,370.427c-5.972,3.441-13.334,3.441-19.302,0
                  c-5.973-3.453-9.66-9.833-9.66-16.724V19.305C52.137,12.413,55.818,6.036,61.792,2.588z" fill="#fff"/>
                </svg>
            </button>`
          : ``
      }
    ${
      window.helpUrl
        ? `<a class="tiger-popper-button mod-button-icon help-button" data-t="view-documentation-popper-button" href="${window.helpUrl}" target="_blank" rel="noopener noreferrer" data-toggle="tooltip" data-placement="right" data-title="View Documentation">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47 60" height="25" width="27" class="tiger-popper-doc-icon mod-rotate">
                  <path d="M38.91,0H6.5V60h47V0ZM17.5,14h10a1,1,0,0,1,0,2h-10a1,1,0,0,1,0-2Zm25,34h-25a1,1,0,0,1,0-2h25a1,1,0,0,1,0,2Zm0-8h-25a1,1,0,0,1,0-2h25a1,1,0,0,1,0,2Zm0-8h-25a1,1,0,0,1,0-2h25a1,1,0,0,1,0,2Zm0-8h-25a1,1,0,0,1,0-2h25a1,1,0,0,1,0,2Z" transform="translate(-6.5)" fill="#fff"></path>
                </svg>
              </a>`
        : ``
    }
    ${
      window.HubSpotConversations || window.Intercom
        ? `<button class="tiger-popper-button mod-button-icon js-activate-chat" data-t="contact-us-popper-button" type="button" data-toggle="tooltip" data-placement="right" data-title="Contact Us">
        ${chatSVG(`mod-rotate`)}
        <span class="tiger-popper-hide">Contact Us</span>
      </button>`
        : ``
    }
      <button class="tiger-popper-button js-activate-help" data-t="help-popper-button" type="button" data-toggle="tooltip" data-placement="right" data-title="Help Centre">Help</button>
    </div>
  `)
  );

};

export { setupHelp };
