/* eslint-disable import/no-unassigned-import */
// @ts-check
import 'baconjs';
import $ from 'jquery';

import { getFileFromEvent, isNotNil, setLocation } from '../utils/helpers';

import * as upload from './file-upload';

const modalTypeDynamic = 'dynamic';
const fileUploadSliderSelector = '.js-move-file-input';
const setUploadErrorSelector = '.js-set-upload-error';
const handleFileChangeClass = 'js-handle-file';
const handleFileChangeSelector = `.${handleFileChangeClass}`;
const uploadSliderModifier = 'is-up';

function displayUploadModal() {
  var modal = Object.create(window.Modal);

  modal.init({
    body: $(getUploadMarkup()),
    footer: $(''),
    isLarge: true,
    modalType: modalTypeDynamic,
    title: 'Upload a PDF or PowerPoint File'
  });
}

const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

const uploadText = isIE11
  ? 'Click to select a file'
  : 'Drag & drop a file or click to select a file';

const getUploadMarkup = () =>
  `<div class="form-element">
    <label class="form-element-label" for="file-upload">
      <span class="form-element-label-text">Select your file</span>
      <span class="form-element-label-error js-set-upload-error"></span>
    </label>

    <div class="file-input">
      <div class="file-input-vertical-controller js-move-file-input">

        <div class="file-input-section-container">
          <div class="file-input-svg-container " data-spec="file-svg-container">
            <svg class="file-input-svg" fill="#ef8023" viewBox="0 0 80 80">
              <path d="M40,80C17.96,80,0,62.04,0,40S17.96,0,40,0s40,17.96,40,40S62.04,80,40,80z M40,4.56 C20.48,4.56,4.56,20.44,4.56,40c0,19.52,15.88,35.44,35.44,35.44c19.52,0,35.44-15.88,35.44-35.44C75.44,20.48,59.52,4.56,40,4.56z"></path>
              <path d="M40,15.92c0.68,0,1.25,0.23,1.71,0.7l16.86,16.86c0.46,0.46,0.7,1.03,0.7,1.71c0,0.69-0.23,1.26-0.69,1.72 s-1.03,0.69-1.72,0.69c-0.68,0-1.25-0.23-1.71-0.7L42.41,24.14v37.54c0,0.66-0.24,1.23-0.71,1.7c-0.47,0.47-1.04,0.71-1.7,0.71 c-0.66,0-1.23-0.24-1.7-0.71s-0.71-1.04-0.71-1.7V24.14L24.85,36.9c-0.46,0.46-1.03,0.7-1.71,0.7c-0.69,0-1.26-0.23-1.72-0.69 s-0.69-1.03-0.69-1.72c0-0.68,0.23-1.25,0.7-1.71l16.86-16.86C38.75,16.15,39.32,15.92,40,15.92z"></path>
            </svg>
          </div>
          <span class="file-input-text">${uploadText}</span>
          <input accept="application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/vnd.openxmlformats-officedocument.presentationml.presentation, .pps" class="file-input-input js-handle-file" id="file-upload" type="file">
        </div>

        <div class="file-input-section-container">
          <div class="upload-container mod-popup">
            <div class="progress">
              <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="0" class="progress-bar js-set-progress-bar" role="progressbar" style="transform: scale(0, 1)"></div>
            </div>
            <span class="upload-text js-set-progress-text">Uploading…</span>
          </div>
        </div>

      </div>
    </div>

  </div>`;

function resetUpload() {
  $(setUploadErrorSelector).text('');
  $(handleFileChangeSelector).val('');
  setUploadPercentage({ lengthComputable: true, loaded: 0, total: 100 });
}

/**
 * @param {{ code: number; message: string; }} param0
 */
function handleError({ code, message }) {
  $(fileUploadSliderSelector).removeClass(uploadSliderModifier);

  const errorMessage =
    code === 1 ? message : 'There was a problem uploading your file. Please try again.';

  resetUpload();
  $(setUploadErrorSelector).text(errorMessage);
}

function getUploadPath() {
  const apiPath = '/api/v4';
  const { customerId, issueId, publicationId } = window.uploadDetails;
  const haveCustomerID = isNotNil(customerId);
  const havePublicationID = isNotNil(publicationId);
  const haveIssueId = isNotNil(issueId);

  if (haveCustomerID && havePublicationID && haveIssueId) {
    return `${apiPath}/upload/${customerId}/${publicationId}/${issueId}/files/`;
  } else if (haveCustomerID && havePublicationID) {
    return `${apiPath}/upload/${customerId}/${publicationId}/files/`;
  }

  return `${apiPath}/upload/files/`;
}

function setUploadPercentage({ lengthComputable, loaded, total }) {
  if (lengthComputable) {
    const modal = document.querySelector('#dynamic-modal');
    const percentage = Math.round((loaded * 100) / total);
    const progressBar = modal.querySelector('.js-set-progress-bar');

    progressBar.setAttribute('aria-valuenow', percentage.toString());
    progressBar.style.transitionDuration = '400ms';
    progressBar.style.transform = `scale(${percentage / 100}, 1)`;

    if (percentage === 100) {
      modal.querySelector('.js-set-progress-text').textContent = 'Processing…';
    }
  }
}

/**
 * @param {number} percentage
 * @returns {void}
 */
function setUploadPercentageNew(percentage) {
  /** @type {HTMLElement | null} */
  const modal = document.querySelector('#dynamic-modal');
  /** @type {HTMLElement | null | undefined} */
  const progressBar = modal?.querySelector('.js-set-progress-bar');

  if (progressBar) {
    progressBar.setAttribute('aria-valuenow', percentage.toString());
    progressBar.style.transitionDuration = '800ms';
    progressBar.style.transform = `scale(${percentage / 100}, 1)`;

    if (percentage === 100) {
      modal.querySelector('.js-set-progress-text').textContent = 'Processing…';
    }
  }
}

function setDataAttrs({ target }) {
  const customerId = target.getAttribute('data-customerid');
  const issueId = target.getAttribute('data-issueid');
  const publicationId = target.getAttribute('data-publicationid');

  window.uploadDetails = {
    customerId,
    issueId,
    publicationId
  };
}

function setUploadListeners() {
  const $document = $(document);

  $document
    .asEventStream('click')
    .filter(e => e.target.classList.contains('modal-close-button'))
    .onValue(() => {
      if (window.jqXHR) {
        window.jqXHR.abort();
      }
    });

  $('.js-show-upload-modal')
    .asEventStream('click')
    .doAction(setDataAttrs)
    .subscribe(displayUploadModal);

  $document
    .asEventStream('change')
    .filter(e => e.target.classList.contains(handleFileChangeClass))
    .doAction(() => $(fileUploadSliderSelector).addClass(uploadSliderModifier))
    .map(({ originalEvent }) => originalEvent)
    .onValue(evt => {
      getFileFromEvent(evt).either(
        () => {},
        file => {
          resetUpload();

          fetch('/api/v4/general/guid')
            .then(res => res.text())
            .then(guid => {
              upload
                .startUpload({
                  // Once IE11 support is dead, switch to crypto.randomUUID.
                  guid: guid,
                  // @ts-ignore
                  file: file,
                  // @ts-ignore
                  uploadPath: getUploadPath(window.uploadDetails),
                  onProgress: setUploadPercentageNew
                })
                .then(uploadResult => {
                  if (uploadResult.status === 'ERROR') {
                    handleError(uploadResult);
                  } else {
                    setLocation(uploadResult.redirectUrl);
                  }
                })
                .catch(err => {
                  handleError({ code: 0, message: err.message });
                });
            })
            .catch(err => {
              handleError({ code: 0, message: err.message });
            });
        }
      );
    });
}

export { setUploadListeners };
