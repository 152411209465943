// @ts-check
import $ from 'jquery';

const cookieNoticeClass = 'js-popup-cookie-notice';

/**
 * @param {string} cookieValue
 * @returns {Promise<void|Response>}
 */
const updateCookiePrefs = cookieValue =>
  fetch('/api/v4/cookie-preferences', {
    body: cookieValue,
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'post'
  }).catch(console.error);

/**
 * @param {string} str
 * @returns {DocumentFragment} */
const makeHtml = str => document.createRange().createContextualFragment(str);

const cookieMessage = `We use necessary cookies to make our site work. We'd also
  like to set optional performance and functional cookies.
  We won't set these optional cookies unless you enable them. Using this tool will set a
  cookie on your device to remember your preferences.`;

// const cookies = {
//   ptCookies: {
//     cookies: [
//       {
//         description: 'This cookie is used to remember your cookie preferences.',
//         name: 'cookie-preferences'
//       },
//       {
//         description:
//           'A proofing token that allows you to temporarily view a document that is yet to be published.',
//         name: 'PTIPROOF*'
//       },
//       {
//         description: 'These are authentication cookies that remembers your browser when you login.',
//         name: 'PTIAU*'
//       },
//       {
//         description:
//           'This cookie verifies that your device has been verified by our secure two-factor authentication system.',
//         name: 'VERIFY*'
//       }
//     ],
//     policyLink: 'https://www.pagetiger.com/terms-and-policies/cookie-policy'
//   },
//   thirdPartyCookies: [
//     {
//       description:
//         'These cookies are used as part of our challenge-response test intended to prevent spambots from abusing the PageTiger system.',
//       name: 'Google reCAPTCHA ',
//       policyLink: 'https://policies.google.com/technologies/types?hl=en',
//       values: ['NID']
//     },
//     {
//       description:
//         'These cookies are used to collect information about how visitors use PageTiger. We use the information to compile reports and to help improve our website. The cookies collect information in a way that does not directly identify anyone, including the number of visitors, where visitors have come from, and the pages they visited.',
//       name: 'Google Analytics',
//       policyLink:
//         'https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage',
//       values: ['NID', '_ga', '_gali', '_gat', '_gid']
//     },
//     {
//       description: 'These cookies are used to provide our live chat support service.',
//       name: 'Intercom',
//       policyLink: 'https://www.intercom.com/help/en/articles/2361922-intercom-messenger-cookies',
//       values: ['intercom-id-*', 'intercom-session-*']
//     },
//     {
//       description: 'These cookies are used process invoice payments and help combat fraud.',
//       name: 'Stripe',
//       policyLink: 'https://stripe.com/cookies-policy/legal',
//       values: ['__stripe_sid', '__stripe_mid', '__stripe_orig_props', 'recent-views', 'scfc']
//     }
//   ]
// };

/**
 * @param {CookieSection} cookieSection
 * @returns {string}
 */
const renderCookieSection = ({ title, text, inputChecked }) => {
  const hasInput = typeof inputChecked === 'boolean';
  const isChecked = inputChecked === true;
  const titleLowered = title.toLocaleLowerCase();
  const cookieDescriptionClass = inputChecked === true ? 'enabled' : 'disabled';
  const enabledDisabledText = isChecked ? 'Enabled' : 'Disabled';

  return `
  <div class="cookie-detail-section">
    <div class="cookie-detail-section-head">
      <h3 class="cookie-detail-section-heading" style="font-size: 20px;">${title}</h3>
        ${
          hasInput
            ? `
            <div class="cookie-detail-input-container">
              <input id="cookies-${titleLowered}" class="js-cookie-input-${titleLowered} visually-hidden cookie-detail-input" ${
                isChecked ? 'checked' : ''
              } type="checkbox">
              <label for="cookies-${titleLowered}" class="cookie-detail-label mod-${cookieDescriptionClass}" aria-label="Toggle ${title} Cookies">
                <span class="cookie-detail-input-text mod-${cookieDescriptionClass}" style="display: block; margin-top: 2px;">${enabledDisabledText}</span>
              </label>
            </div>`
            : `<span class="cookie-detail-input-text mod-enabled">Always Enabled</span>`
        }
    </div>
    <p class="cookie-detail-section-text">${text}</p>
  </div>`;
};

/**
 * @param {{advertising: Boolean, functional: Boolean, performance: Boolean }} pageTurn
 * @return {string}
 */
const getCookieMarkup = ({ functional, performance }) => `
  <form class="cookie-form js-cookie-form" data-t="cookie-modal">
    <div class="cookie-section">
        <span class="cookie-text">${cookieMessage}</span>

        <div class="cookie-detail">
          ${renderCookieSection({
            inputChecked: null,
            text: 'These cookies are essential in order to enable you to move around the site and use its features, such as accessing secure areas of the site. Without these cookies, services you have asked for cannot be provided.',
            title: 'Necessary'
          })}

          ${renderCookieSection({
            inputChecked: performance,
            text: 'Performance cookies are generally third-party cookies from vendors we work with or who work on our behalf that collect information about your visit and use of the PageTiger website, for instance which pages you visit the most often, and if you get error messages from web pages.',
            title: 'Performance'
          })}

          ${renderCookieSection({
            inputChecked: functional,
            text: 'Functionality cookies allow a site to remember choices you make (such as your user name, language or the region you are in) and provide more enhanced, personal features.',
            title: 'Functional'
          })}
        </div>

        <div class="cookie-detail-buttons">
          <button onclick="this.form.submitted=this.value;" value="accept" class="cookie-button mod-tertiary js-accept-all" type="submit" data-t="cookie-accept-all" aria-label="Accept All Cookies">Accept All</button>
          <button onclick="this.form.submitted=this.value;" value="reject" class="cookie-button mod-tertiary js-toggle-cookie-settings" data-t="cookie-settings" type="submit" aria-label="Reject All Cookies">Reject All</button>
          <button class="cookie-button mod-primary js-accept-custom" type="submit" data-t="cookie-accept-custom" aria-label="Accept Choosen Cookies">Confirm Choices</button>
        </div>
    </div>
</form>
`;

window.ptiCookie = () => {
  const {
    advertising = false,
    functional = false,
    performance = false
  } = window.cookieValues || {};

  var modal = Object.create(window.Modal);

  $(`.${cookieNoticeClass}`).hide();

  modal.init({
    body: $(
      getCookieMarkup({
        advertising,
        functional,
        performance
      })
    ),
    footer: $(''),
    isLarge: false,
    modalType: 'dynamic',
    title: 'Cookie Preferences'
  });

  const $$ = document.querySelector.bind(document);
  const functionalInput = $$('.js-cookie-input-functional');
  const performanceInput = $$('.js-cookie-input-performance');
  const form = $$('.js-cookie-form');

  if (
    !form ||
    !(functionalInput instanceof HTMLInputElement) ||
    !(performanceInput instanceof HTMLInputElement)
  ) {
    return;
  }

  const cookieNotice = document.querySelector(`.${cookieNoticeClass}`);

  if (cookieNotice) {
    cookieNotice.parentNode?.removeChild(cookieNotice);
  }

  form.addEventListener('change', e => {
    const { target } = e;

    if (target instanceof HTMLInputElement && target.type === 'checkbox') {
      const label = target.nextElementSibling?.firstElementChild;

      if (label) {
        label.textContent = target.checked ? 'Enabled' : 'Disabled';
        label.classList.remove(target.checked ? 'mod-disabled' : 'mod-enabled');
        label.classList.add(target.checked ? 'mod-enabled' : 'mod-disabled');
      }
    }
  });

  form.addEventListener('submit', e => {
    e.preventDefault();
    e.stopPropagation();

    const { target } = e;
    var advertising = false;
    var functional = false;
    var performance = false;

    if (target instanceof HTMLFormElement) {
      if (target.submitted === 'accept') {
        advertising = true;
        functional = true;
        performance = true;
      } else if (target.submitted === 'reject') {
        advertising = false;
        functional = false;
        performance = false;
      } else {
        functional = functionalInput.checked;
        performance = performanceInput.checked;
      }
    }

    const newCookieSettings = { advertising, functional, performance };

    updateCookiePrefs(JSON.stringify(newCookieSettings));

    if (window.gtag) {
      window.gtag('consent', 'update', {
        analytics_storage: performance ? 'granted' : 'denied'
      });
    }

    window.cookieValues = newCookieSettings;

    $$('main')?.focus();
    modal.close();
  });
};

/* eslint-disable max-statements, no-param-reassign, no-shadow */
// @ts-check
const cookieNotice = () => {
  const cookieFormClass = 'js-cookie-banner-form';

  if (window.cookieValues !== null) {
    return;
  }

  document.body.appendChild(
    makeHtml(`<div class="js-popup-cookie-notice cookie-notice" tabindex="-1">
        <form class="cookie-simple-form ${cookieFormClass}">
          <div>
            <h2 class="cookie-notice-heading">Cookies</h2>
            <p class="cookie-notice-text">${cookieMessage}</p>
          </div>

          <div class="cookie-notice-button-container">
            <button class="cookie-notice-button" onclick="this.form.submitted=this.value;" value="accept" type="submit" aria-label="Accept all cookies">Accept All</button>
            <button class="cookie-notice-button" onclick="this.form.submitted=this.value;" value="reject" type="submit" aria-label="Reject all cookies">Reject All</button>
            <button class="cookie-notice-button mod-secondary" onclick="javascript: window.ptiCookie();" type="button" aria-label="Customise cookies">Customise</button>
          </div>
        </form>
      </div>`)
  );

  document.querySelector(`.${cookieFormClass}`)?.addEventListener('submit', e => {
    // var advertising = false;
    var functional = false;
    var performance = false;

    e.preventDefault();

    if (e.target.submitted === 'accept') {
      // advertising = true;
      functional = true;
      performance = true;

      if (window.gtag) {
        window.gtag('consent', 'update', {
          analytics_storage: performance ? 'granted' : 'denied'
        });
      }
    }

    const newCookieSettings = {
      advertising: false,
      functional,
      performance
    };

    updateCookiePrefs(JSON.stringify(newCookieSettings));

    window.cookieValues = newCookieSettings;

    $(`.${cookieNoticeClass}`).remove();
    $('main').trigger('focus');
  });
};

export { cookieNotice };
