/* eslint-disable import/no-unassigned-import */
// @ts-check
import 'url-polyfill';
import compose from 'ramda/src/compose';
import head from 'ramda/src/head';
import prop from 'ramda/src/prop';
import ifElse from 'ramda/src/ifElse';
import isNil from 'ramda/src/isNil';
import not from 'ramda/src/not';
import Maybe from 'crocks/Maybe';
import { tryCatch } from 'ramda';

/**
 * @param {string} url
 * @return {any}
 */
const parseUrl = url => {
  try {
    return Maybe.Just(new URL(url));
  } catch (e) {
    console.warn(e);

    return Maybe.Nothing();
  }
};

// -- fromNullable :: a -> Maybe a
const fromNullable = ifElse(isNil, Maybe.Nothing, Maybe.Just);

// -- eventFiles :: DomEvent -> [FileObject]
const eventFiles = compose(prop('files'), prop('target'));

// -- eventValue :: DomEvent -> String
const eventValue = compose(prop('value'), prop('target'));

// -- isNotNil :: a -> Boolean
const isNotNil = compose(not, isNil);

// -- safeHead :: [a] -> Maybe a
const safeHead = compose(fromNullable, head);

// --- getFileOnChange :: Event -> Maybe FileObject
const getFileFromEvent = compose(safeHead, eventFiles);

// -- setLocation :: String -> IO Navigation
const setLocation = newLocation => window.location.assign(newLocation);

// -- getEnvironment :: String -> String
const getEnvironment = host => {
  const dev = 'dev';
  const staging = 'staging';

  if (host.includes(dev)) {
    return dev;
  } else if (host.includes(staging)) {
    return staging;
  }

  return 'production';
};

/**
 * @param {number} ms
 * @return {Promise<void>}
 */
const delay = ms => new Promise(res => setTimeout(() => res(), ms));


// -- base64ToUtf8 : String -> String
const base64EncodeToUtf8 = str =>
  btoa(
    // eslint-disable-next-line no-unused-vars
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_match, p1) =>
      String.fromCharCode(parseInt(p1, 16))
    )
  );

// -- base64ToUtf8 : String -> String
const base64DecodeToUtf8 = str =>
  decodeURIComponent(
    Array.prototype.map
      .call(atob(str), char => `%${`00${char.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

/**
 * @param {string} json
 * @return {any}
 */
const tryParseJson = json => {
  tryCatch(() => JSON.parse(json));
};

/**
 * @param {number} min
 * @param {number} max
 * @param {number} num
 * @returns {number}
 */
function clamp(min, max, num) {
  return Math.min(Math.max(num, min), max);
}

export {
  clamp,
  tryParseJson,
  delay,
  eventFiles,
  eventValue,
  fromNullable,
  getEnvironment,
  isNotNil,
  parseUrl,
  safeHead,
  getFileFromEvent,
  setLocation,
  base64EncodeToUtf8,
  base64DecodeToUtf8
};
