/** @type {RequestInit} */
const fetchOptions = {
  cache: 'no-cache',
  credentials: 'same-origin',
  headers: {
    'Content-Type': 'application/json'
  },
  method: 'GET',
  mode: 'cors',
  redirect: 'follow',
  referrerPolicy: 'no-referrer'
};

export { fetchOptions };
