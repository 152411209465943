/* eslint-disable vars-on-top, complexity */
import $ from 'jquery';

import { Modal } from './modal';
import { setLocation } from './helpers';
import { getDomElement, getDomElements, getValue, hideElement } from './dom';

function getFooterButtons(message, redirectURL, requirePrompt) {
  const okButton =
    '<button type="button" data-micromodal-close class="button primary-button">OK</button>';

  // Simply display a message
  if (message && !redirectURL && !requirePrompt) {
    return okButton;
    // Display a message with a required redirect
  } else if (message && redirectURL && !requirePrompt) {
    return '<button type="button" data-action="redirect" class="button primary-button">OK</button>';
    // Display a message with a required redirect
  } else if (message && redirectURL && requirePrompt) {
    return (
      '<button type="button" data-micromodal-close class="button tertiary-link-style-button modal-cancel-button">Cancel</button>' +
      '<button type="button" data-action="redirect" class="button primary-button">OK</button>'
    );
  }

  return okButton;
}

/* eslint-disable */
function processMessages() {
  getDomElement('#hdnMessage').map(messageEl => {
    const message = messageEl.value;
    const redirectURL = getDomElement('#hdnRedirectUrl').map(getValue).option('');
    const requirePrompt = getDomElement('#hdnRedirectPrompt').map(getValue).option('');
    const footerButtons = getFooterButtons(message, redirectURL, requirePrompt);
    const hideClose = getDomElement('#hdnHideClose').map(getValue).option('');
    const okOnly = getDomElement('#hdnOkOnly').map(getValue).option('');
    const customCancelText = getDomElement('#hdnCustomCancelText').map(getValue).option('');
    const customCancelAction = getDomElement('#hdnCustomCancelAction').map(getValue).option('');

    document.body.addEventListener('click', ({ target }) => {
      const isRedirect = target.getAttribute('data-action') === 'redirect';

      if (isRedirect && target.classList.contains('primary-button')) {
        setLocation(redirectURL);
      }

      if (isRedirect && target.classList.contains('modal-cancel-button') && customCancelAction) {
        setLocation(customCancelAction);
      }
    });

    message && showMessage(message, footerButtons);

    if (okOnly.toLowerCase() === 'y') {
      getDomElements('#message-modal [data-micromodal-close]').map(hideElement);
    }

    if (hideClose.toLowerCase() === 'y') {
      getDomElement('#message-modal .modal-close-button').map(hideElement);
    }

    if (customCancelText && customCancelAction) {
      getDomElement('#message-modal .modal-cancel-button').map(cancelBtn => {
        cancelBtn.removeAttribute('data-micromodal-close');
        cancelBtn.textContent = customCancelText;
        cancelBtn.setAttribute('data-action', 'redirect');
      });
    }
  });
}
/* eslint-enable */

function showMessage(message, footerButtons) {
  var modal = Object.create(Modal);

  modal.init({
    body: $(`<p>${message}</p>`).html(),
    footer: footerButtons,
    modalType: 'message',
    title: 'Information'
  });
}

export { processMessages, showMessage };
