import { clamp } from '../utils/helpers';

type UploadOptions = {
  guid: string;
  file: File;
  uploadPath: string;
  onProgress: (progress: number) => void;
  chunkSize?: number; // 1024
};

type UploadOptionsPrivate = UploadOptions & {
  totalChunks: number;
  chunkNo: number;
  chunkSize: number;
};

type UploadSuccessResult = {
  status: typeof OK;
  redirectUrl: string;
};

type FullError = ErrorResponse & { status: typeof ERR };

type UploadResult = UploadSuccessResult | FullError;

type ErrorResponse = {
  message: string;
  code: 0 | 1 | 2;
};

type OkResponse = {
  status: typeof OK;
  data: string | undefined;
};

type ChunkResponse = OkResponse | FullError;

const UPLOAD_FETCH_OPTIONS: RequestInit = {
  method: 'post',
  credentials: 'same-origin',
  cache: 'no-cache',
  referrerPolicy: 'no-referrer'
};

const OK = 'OK';
const ERR = 'ERROR';
const defaultChunkSize = 1024 ** 2; // 1MB
const maxChunkSize = defaultChunkSize * 20; // 20MB
const minChunkSize = defaultChunkSize / 2; // 500KB

/**
 * @param str
 * @returns true if the input is a string
 */
function isString(str: unknown): str is string {
  return typeof str === 'string';
}

/**
 * @param str
 * @returns true if the string is non-empty
 */
function isNonEmptyString(str: string) {
  return str.length > 0;
}

/**
 * The public API for uploading files in small chunks.
 * @param options
 * @returns An upload result
 */
async function startUpload(options: UploadOptions): Promise<UploadResult> {
  const chunkSize = clamp(minChunkSize, maxChunkSize, options.chunkSize ?? defaultChunkSize);
  const totalChunks = Math.ceil(options.file.size / chunkSize);

  return startUploadHelper({
    ...options,
    chunkSize,
    totalChunks,
    chunkNo: 0
  });
}

/**
 * Creates the FormData required by the backend for each chunk.
 * @param blob - The specific raw data of the chunk we're uploading
 * @param opts - The options
 * @returns The required form data.
 */
function createFormData(blob: Blob, opts: UploadOptionsPrivate): FormData {
  const formData = new FormData();
  formData.append('filename', encodeURIComponent(opts.file.name));
  formData.append('file', blob);
  formData.append('guid', opts.guid);
  formData.append('chunk', opts.chunkNo.toString());
  formData.append('chunk_count', opts.totalChunks.toString());

  return formData;
}

/**
 * A recusive function that will keep uploading chunks until completion.
 * @param opts
 */
async function startUploadHelper(opts: UploadOptionsPrivate): Promise<UploadResult> {
  const nextChunk = opts.chunkNo + 1;
  const currentSlice = opts.chunkNo * opts.chunkSize;
  const nextSlice = nextChunk * opts.chunkSize;
  const blob = opts.file.slice(currentSlice, nextSlice);
  const result = await uploadChunk(opts.uploadPath, createFormData(blob, opts), encodeURIComponent(opts.file.name));
  const hasFinished = nextSlice >= opts.chunkSize && result.status === OK;

  opts.onProgress((opts.chunkNo / opts.totalChunks) * 100);

  if (hasFinished && isString(result.data) && isNonEmptyString(result.data)) {
    return { status: OK, redirectUrl: JSON.parse(result.data) };
  }

  if (result.status === ERR) {
    return result;
  }

  return startUploadHelper({ ...opts, chunkNo: nextChunk });
}

/**
 * Uploads a small chunk of a file
 * @param path - The URL endpoint to upload to
 * @param formData - The chunks FormData
 * @param fileName - The name of the file
 * @returns
 */
async function uploadChunk(
  path: string,
  formData: FormData,
  fileName: string
): Promise<ChunkResponse> {
  try {
    const res = await fetch(path, {
      ...UPLOAD_FETCH_OPTIONS,
      body: formData,
      headers: {
        'Content-Disposition': `form-data; filename="${fileName}"`
      }
    });

    if (!res.ok) {
      const responseData = (await res.json()) as ErrorResponse;

      return { status: ERR, ...responseData };
    }

    return { status: OK, data: await res.text() };
  } catch (e) {
    const errorMessage = e instanceof Error ? e.message : 'Something went wrong';

    return { status: ERR, code: 0, message: errorMessage };
  }
}

export { startUpload };
