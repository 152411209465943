/* eslint-disable import/no-unassigned-import */
import 'core-js/stable/array/from';
import 'core-js/stable/object/assign';
import 'core-js/modules/es.symbol.iterator';
import $ from 'jquery';
import MicroModal from 'micromodal';
import { Spinner } from 'spin.js';

import 'unfetch/polyfill';
import { Modal } from './utils/modal';
import { processMessages } from './utils/flash-message';
import { setupHelp } from './help/admin-help';
import { getDomElement } from './utils/dom';
import { cookieNotice } from './utils/cookie';

window.$ = $;
window.jQuery = $;
window.MicroModal = MicroModal;
window.Modal = Modal;
window.Spinner = Spinner;

document.addEventListener('DOMContentLoaded', () => {
  // eslint-disable-next-line array-callback-return
  getDomElement('.js-activate-cookie-modal').map(el => {
    el.addEventListener('click', window.ptiCookie);
  });

  if (window.cookieValues === null) {
    cookieNotice();
  }

  processMessages();
  setupHelp();
});
