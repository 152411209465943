const MAX_FILE_SIZE = 1024 ** 2 * 200; // 200MB

/**
 * Takes the number of bytes and returns the human readable format. 1048576 -> 1MB
 * @param bytes The number of bytes
 * @return Human readable version
 */
function bytesToHumanReadable(bytes: number) {
  if (bytes === 0) {
    return '0.00 B';
  }
  const e = Math.floor(Math.log(bytes) / Math.log(1024));

  return `${(bytes / Math.pow(1024, e)).toFixed(2)} ${' KMGTP'.charAt(e)}B`;
}

/**
 * Sums the file sizes of all the files within a file list
 * @param files
 */
function sumOfFileSizes(files: FileList) {
  return [...files].reduce((totalSize, file) => file.size + totalSize, 0);
}

function detectFileChange() {
  document.addEventListener('change', e => {
    const fileInput = e.target as HTMLInputElement;

    if (fileInput.nodeName !== 'INPUT' && fileInput.type !== 'input') {
      return;
    }

    if (fileInput.files) {
      if (sumOfFileSizes(fileInput.files) >= MAX_FILE_SIZE) {
        fileInput.value = '';
        alert(
          `File too large. The maximum file size for uploads is ${bytesToHumanReadable(
            MAX_FILE_SIZE
          )}.`
        );
      }
    }
  });
}

document.addEventListener('DOMContentLoaded', detectFileChange);
