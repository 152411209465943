/* eslint-disable no-param-reassign */
// @ts-check
import compose from 'ramda/src/compose';
import curry from 'ramda/src/curry';

import { fromNullable } from './helpers';

const isShownClass = 'is-shown';
const isHiddenClass = 'is-hidden';

const $$ = document.querySelector.bind(document);
const $$$ = document.querySelectorAll.bind(document);

/**
 * @param {string} selector
 * @return {any}
 */
const getDomElement = selector => compose(fromNullable, $$)(selector);

/**
 * @param {string} selector
 * @return {HTMLElement[]}
 */
const getDomElements = selector =>
  compose(elements => Array.prototype.slice.call(elements), $$$)(selector);

/**
 * @param {string} htmlString
 * @return {ChildNode}
 */
const makeHtml = htmlString => {
  var doc = new DOMParser().parseFromString(htmlString, 'text/html');

  return doc.body.firstElementChild;
};

/**
 * @param {string} className
 * @returns {(el : HTMLElement) => void}
 */
const addClass = className => el => el.classList.add(className);

/**
 * @param {string} className
 * @returns {(el : HTMLElement) => void}
 */
const removeClass = className => el => el.classList.remove(className);

/**
 * @param {HTMLElement} element
 * @return {HTMLElement}
 */
function showElement(element) {
  element.classList.remove(isHiddenClass);
  element.classList.add(isShownClass);

  return element;
}

/**
 * @param {HTMLElement} element
 * @return {HTMLElement}
 */
function hideElement(element) {
  element.classList.remove(isShownClass);
  element.classList.add(isHiddenClass);

  return element;
}

/**
 * @param {HTMLElement} element
 * @return {void}
 */
function removeElement(element) {
  element.parentElement?.removeChild(element);
}


// -- setFieldValue :: DOMNode -> IO DOM
const setFieldValue = curry((element, value) => {
  element.value = value;
});

const setTextContent = curry((element, value) => {
  element.textContent = value;
});

/**
 * @param {HTMLSelectElement} element
 * @return {string}
 */
const getSelectedValue = element => element.options[element.selectedIndex].value;

/**
 * @param {HTMLInputElement} element
 * @return {string}
 */
const getValue = element => element.value;

/**
 * @param {HTMLInputElement} element
 * @return {boolean}
 */
const getChecked = element => element.checked;

// -- addListener : String -> Fn -> HTMLElement
const addListener = curry((type, fn, el) => el.addEventListener(type, fn));

const escapeHTML = unsafe => {
  return unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');
};

export {
  $$,
  addClass,
  addListener,
  escapeHTML,
  getChecked,
  getDomElement,
  getDomElements,
  getSelectedValue,
  getValue,
  hideElement,
  makeHtml,
  removeClass,
  removeElement,
  showElement,
  setFieldValue,
  setTextContent
};
