import 'core-js/stable/array/from';
import 'core-js/stable/object/assign';
import 'core-js/modules/es.symbol.iterator';
import $ from 'jquery';
import MicroModal from 'micromodal';

const isLargeClass = 'is-large';
const isFullScreen = 'is-full-screen';

/**
 * @typedef {Object} ModalOptions
 * @property {string} modalType
 * @property {() => void} onClose
 * @property {() => void} onShow
 * @property {boolean} isLarge
 * @property {number} withWidth
 */

const Modal = {
  /**
   * @returns {void}
   */
  close() {
    this.setContent('', '', '');

    MicroModal.close(`${this.modalType}-modal`);

    $(`#${this.modalType}-modal .modal-container`)
      .removeClass(isLargeClass)
      .removeClass(isFullScreen);
  },

  /**
   * @param {ModalOptions} options
   * @returns {void}
   */
  init(options) {
    const config = {
      onClose: () => {
        if (options.onClose) {
          options.onClose();
          this.setContent('', '', '');
        }
      },
      onShow: () => {
        if (options.onShow) {
          options.onShow();
        }
      }
    };

    MicroModal.init({ disableScroll: true });

    this.modalType = options.modalType;
    const modalContainer = $(`#${this.modalType}-modal .modal-container`);

    this.title = modalContainer.find('.modal-header-title');
    this.body = modalContainer.find('.modal-body');
    this.footer = modalContainer.find('.modal-footer');

    if (options.withWidth) {
      modalContainer.width(options.withWidth);
    } else if (options.isLarge) {
      modalContainer.addClass(isLargeClass);
    } else if (options.isFullScreen) {
      modalContainer.addClass(isFullScreen);
    } else {
      modalContainer.removeClass(isLargeClass);
    }

    this.setupContent(options);
    MicroModal.show(`${this.modalType}-modal`, config);
  },

  /**
   * @param {string} title
   * @param {string} content
   * @param {string} footer
   * @returns {void}
   */
  setContent(title, content, footer) {
    this.title.html(title);
    this.body.html(content);
    this.footer.html(footer);
  },

  /**
   * @param {ModalOptions} options
   * @returns {void}
   */
  setupContent(options) {
    this.setContent(options.title, options.body, options.footer);
  }
};

export { Modal };
